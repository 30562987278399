<template>
  <div class="p-4 treatment-history-doc-emr">
    <h6>
      <router-link v-if="$route.query.dateRange && $route.query.clinicName" :to="{
        name: backRoute,
        query: {
          clinicId: $route.query.clinicId,
          clinicName: $route.query.clinicName,
          year: $route.query.year,
          month: $route.query.month,
          dateRange: $route.query.dateRange,
          activeView: $route.query.activeView,
        },
      }"><span @click="$router.go(-1)" role="link">&#8592; Go back</span></router-link>
      <router-link v-else-if="$route.query.dateRange && !$route.query.clinicName" :to="{
        name: backRoute,
        params: { clinicId: $route.query.clinicId },
        query: {
          year: $route.query.year,
          month: $route.query.month,
          dateRange: $route.query.dateRange,
          activeView: $route.query.activeView,
        },
      }"><span @click="$router.go(-1)" role="link">&#8592; Go back</span></router-link>
      <router-link v-else-if="!$route.query.dateRange && $route.query.backRoute" :to="{ name: backRoute }"><span
          @click="$router.go(-1)" role="link">&#8592; Go back</span></router-link>
      <router-link v-else :to="{
        name: backRoute,
        params: { patientId: $route.params.patientId },
      }"><span @click="$router.go(-1)" role="link">&#8592; Go back</span></router-link>
    </h6>
    <!-- Start Treatment Details -->
    <div v-if="loaderKeys.appointmentData || loaderKeys.patientHeaderTreatmentDetails || loaderKeys.patientDetails" class="mt-8">
      <div class="animate-pulse skeleton-line w-1/6 h-8 mb-3"></div>
      <div class="animate-pulse skeleton-line w-48 h-5 mb-3"></div>
      <div class="animate-pulse skeleton-line w-24 h-5 mb-3"></div>
      <div class="animate-pulse skeleton-line w-32 h-5"></div>
    </div>
    <div v-else class="patient-header-details mt-8">
      <h3 class="capitalize">{{ appointmentData.patientDetail.name ? appointmentData.patientDetail.name : "N/A" }}</h3>
      <p>
        {{
          appointmentData.appointmentDateTime ?
          moment(appointmentData.appointmentDateTime).format( "LLL") : "N/A"
        }} <br />
        Owner:
        {{
          appointmentData.treatmentOwnerDetail && appointmentData.treatmentOwnerDetail.name ?
          appointmentData.treatmentOwnerDetail.name : "N/A"
        }} <br />
        Clinic:
        {{
          appointmentData.clinicDetail && appointmentData.clinicDetail.clinicName ? appointmentData.clinicDetail.clinicName : "N/A"
        }}
      </p>
    </div>
    <hr class="mt-6 mb-8 divider" />

    <div class="treatment-details-wrapper mt-5">
      <div>
        <!-- Patient Treatment Details -->
        <div class="patient-treatment-details">
          <h5>Treatment Details</h5>
          <div v-if="loaderKeys.appointmentData || loaderKeys.patientTreatmentDetails">
            <div class="animate-pulse skeleton-line w-1/2 h-5 mb-3"></div>
            <div class="animate-pulse skeleton-line w-1/2 h-5"></div>
          </div>
          <div v-else>
            <p><strong>Date:</strong>
            {{
              appointmentData.appointmentDateTime ?
              moment(appointmentData.appointmentDateTime).format( "LLL") : "N/A"
            }}
            </p>
            <p><strong>Doctor:</strong>
              {{
                appointmentData.doctorDetail && appointmentData.doctorDetail.name ?
                appointmentData.doctorDetail.name : "N/A"
              }}
            </p>
          </div>
        </div>
        <!-- Consent Form -->
        <div class="consent-form">
          <h5>Consent Form</h5>
          <div v-if="loaderKeys.appointmentData || loaderKeys.consentForm">
            <div class="animate-pulse skeleton-line w-64 h-5 mb-4"></div>
            <div class="animate-pulse skeleton-button w-full h-10"></div>
          </div>
          <div v-else>
            <div v-if="appointmentData.consent && appointmentData.consent.pdf">
              <p class="mb-4 text-sm"><span class="font-medium">Signed at </span> {{ moment(appointmentData.consent.signedAt).format("DD/MM/YYYY") }}</p>
              <vs-button
                class="w-full"
                color="primary"
                type="border"
                @click="viewMedia(appointmentData.consent.pdf)"
              >View Consent Form</vs-button>
            </div>
            <p class="font-normal my-6 text-sm" v-else>You must complete the consent form through the mobile app. The form will display
              here once completed.</p>
          </div>
        </div>
        <!-- Patient Products -->
        <div class="patient-treatment-products">
          <div class="flex justify-between">
            <h5>Products</h5>
            <vs-button color="primary" type="border"
              @click="producstDrawer = !producstDrawer"
              v-if="isLoginUserEqualToTreatmentOwner && isDoctorEMR"
              >
              + Add
            </vs-button>
          </div>
          <div v-if="loaderKeys.appointmentData || loaderKeys.patientProducts">
            <div class="animate-pulse skeleton-line w-48 h-5 mb-2"></div>
            <div class="animate-pulse skeleton-line w-64 h-5 mb-4"></div>
          </div>
          <div v-else>
            <div v-if="appointmentData.treatmentPlans && appointmentData.treatmentPlans.length" class="mt-4">
              <div v-for="treatment in appointmentData.treatmentPlans" :key="treatment._id">
                <p><strong>{{ treatment.productName }}</strong></p>
                <div v-for="(part, index) in treatment.parts" :key="index">
                  <p class="leading-none pb-2">{{ part.area }} &#183; {{ part.amount }} {{ treatment.unit }}</p>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="my-2">Build your patient treatment by pressing "+ Add"</p>
            </div>
          </div>
        </div>
        <!-- After Care -->
        <div class="patient-aftercare">
          <div class="aftercare-btn-responsive items-center justify-between">
            <h5>Aftercare</h5>
            <GenerateAftercare v-if="showSendAftercareEmail && isLoginUserEqualToTreatmentOwner" :treatmentPlans="appointmentData.treatmentPlans" :aftercareDetails="appointmentData.aftercareDetails" @updateAftercareDetails="updateAftercareDetails" />
          </div>
          <div v-if="loaderKeys.appointmentData || loaderKeys.aftercare">
            <div class="animate-pulse skeleton-line w-48 h-5 mb-4"></div>
            <div class="animate-pulse skeleton-line w-64 h-5 mb-4"></div>
          </div>
          <div v-else>
            <div v-for="(aftercare, index) in appointmentData.aftercareDetails.aftercareDocumentHistory" :key="aftercare._id" class="mb-2 ">
              <p class="mt-4 text-sm" v-if="aftercare.afterCareDocument"><span class="font-medium">Created: </span> {{ aftercare.createdAt | date_formatter }}</p>
              <p class="mb-4 text-sm" v-if="aftercare.afterCareDocumentEmailSent"><span class="font-small">Document sent on {{ aftercare.afterCareDocumentEmailSent | date_formatter }}</span></p>

              <div v-if="aftercare.afterCareDocument" class="aftercare-btn-responsive gap-3">
                <vs-button
                  class="w-full p-2 mt-2"
                  color="primary"
                  type="border"
                  @click="viewMedia(aftercare.afterCareDocument)"
                >
                  View Document
                </vs-button>
                <SendAftercareDocument
                  v-if="index === 0 && showSendAftercareEmail && isLoginUserEqualToTreatmentOwner"
                  :historyId="aftercare._id"
                  :appointment="appointmentData"
                />
              </div>
            </div>
            <p class="text-sm font-normal" v-if="appointmentData.treatmentPlans && !appointmentData.treatmentPlans.length">To generate an aftercare plan, select the applicable product(s) and complete the consent form using a mobile device.</p>
          </div>
        </div>
        <!-- Patient Details -->
        <div class="patient-details">
          <div class="patient-meds">
            <div class="flex justify-between items-start">
              <h5>Patient Details</h5>
              <button class="underline text-base border-none bg-white shadow-none cursor-pointer mt-1" color="primary"
                type="flat" @click="editPatientDetailsDrawer()" v-if="isDoctorEMR"> Edit </button>
            </div>
            <div>
              <p>Allergies</p>
              <div v-if="loaderKeys.appointmentData || loaderKeys.patientDetails" class="animate-pulse skeleton-line w-32 h-5 ml-4 mt-4"></div>
              <div v-else>
                <div v-if="patientDetails.allergies && patientDetails.allergies.length">
                  <ul
                    v-for="(allergy, index) in patientDetails.allergies"
                    :key="index"
                  >
                    <li>{{ allergy }}</li>
                  </ul>
                </div>
                <div v-else>
                  <ul>
                    <li>N/A</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <p>Regular Medication</p>
              <div v-if="loaderKeys.appointmentData || loaderKeys.patientDetails" class="animate-pulse skeleton-line w-32 h-5 ml-4 mt-4"></div>
              <div v-else>
                <div v-if="patientDetails.regularMedications && patientDetails.regularMedications.length">
                  <ul
                    v-for="(regularMedication, index) in patientDetails.regularMedications"
                    :key="index"
                  >
                    <li>{{ regularMedication }}</li>
                  </ul>
                </div>
                <div v-else>
                  <ul>
                    <li>N/A</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <p>Medical Diagnosis</p>
              <div v-if="loaderKeys.appointmentData || loaderKeys.patientDetails" class="animate-pulse skeleton-line w-48 h-5 ml-4 mt-4"></div>
              <div v-else>
                <div v-if="patientDetails.regularMedications && patientDetails.regularMedications.length">
                  <ul
                    v-for="(previousMedicalDiagnose, index) in patientDetails.previousMedicalDiagnoses"
                    :key="index"
                  >
                    <li>{{ previousMedicalDiagnose }}</li>
                  </ul>
                </div>
                <div v-else>
                  <ul>
                    <li>N/A</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <p>Date of Birth</p>
              <div v-if="loaderKeys.appointmentData || loaderKeys.patientDetails" class="animate-pulse skeleton-line w-32 h-5 ml-4 mt-4"></div>
              <ul v-else>
                <li>{{ patientDetails.dateOfBirth ? moment(patientDetails.dateOfBirth).format("DD MMM YYYY") : "N/A" }} </li>
              </ul>
            </div>
            <div>
              <p>Phone Number</p>
              <div v-if="loaderKeys.appointmentData || loaderKeys.patientDetails" class="animate-pulse skeleton-line w-32 h-5 ml-4 mt-4"></div>
              <ul v-else>
                <li>{{ patientDetails.contactNumber ? patientDetails.contactNumber : "N/A" }}</li>
              </ul>
            </div>
            <div>
              <p>Email</p>
              <div v-if="loaderKeys.appointmentData || loaderKeys.patientDetails" class="animate-pulse skeleton-line w-32 h-5 ml-4 mt-4"></div>
              <ul v-else>
                <li>{{ patientDetails.email ? patientDetails.email : "N/A"}}</li>
              </ul>
            </div>
            <div>
              <p>Residential Address</p>
              <div v-if="loaderKeys.appointmentData || loaderKeys.patientDetails" class="animate-pulse skeleton-line w-48 h-5 ml-4 mt-4"></div>
              <ul v-else>
                <li>{{ patientDetails.address ? patientDetails.address : "N/A"}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- Patient Treatment Photos -->
        <div class="patient-treatment-photos">
          <h5>Treatment Photos/Videos</h5>
          <div
            @dragover.prevent="handleDragOver('before')"
            @dragleave="handleDragLeave('before')"
            @drop.prevent="handleDrop($event, true, 'beforePhotoContainer')"
            :class="`before-photos mt-12 ${ notBasicUser ? '' : 'pointer-events-none' }`"
          >
            <div class="flex justify-between md:flex-row flex-col gap-4">
              <p>Before Photos</p>
              <vs-button v-if="notBasicUser" color="primary" type="border" @click="handleBeforeUploadClick" :disabled="loaderKeys.preTreatmentPhotos || disableBeforeUploadBtn || dragState.before">
                <vs-icon
                  v-if="disableBeforeUploadBtn"
                  icon="refresh"
                  class="animate-spin"
                ></vs-icon>
                <span v-else>+</span>
                Add
              </vs-button>
              <input
                ref="beforePhotoRef"
                type="file"
                multiple
                accept=".png,.jpg,.mp4,.mov"
                @change="uploadImage($event, true, 'beforePhotoContainer')"
                id="file-input"
                hidden
              />
            </div>
            <div v-if="loaderKeys.appointmentData || loaderKeys.preTreatmentPhotos || disableBeforeUploadBtn">
              <div class="flex gap-4 flex-wrap my-4">
                <div v-for="i in 3" :key="`before-${i}`" class="animate-pulse skeleton-image w-48 h-48"></div>
              </div>
            </div>
            <div v-else class="relative">
              <!-- Drag and Drop Overlay -->
              <div
                v-if="dragState.before"
                class="drag-overlay bg-white absolute inset-0 bg-opacity-75 z-50 flex items-center justify-center rounded-lg gap-2"
              >
                <feather-icon icon="UploadIcon" class="text-primary2 text-opacity-50" />
                <p class="text-primary2 text-opacity-50 text-2xl font-bold">Drop files here</p>
              </div>
              <div id="beforePhotoContainer" class="flex gap-4 flex-wrap my-4 relative" v-if="appointmentData.preTreatmentPhotos && appointmentData.preTreatmentPhotos.length">
                <div
                  v-for="photo in appointmentData.preTreatmentPhotos"
                  :key="photo.id"
                  class="media-item-container relative w-48 h-48"
                >
                  <!-- Hover overlay with options -->
                  <div class="media-hover-overlay">
                    <div class="media-actions">
                      <vs-button
                        type="flat"
                        icon="visibility"
                        icon-pack="material-icons"
                        @click="viewMedia(photo.url)"
                        class="mr-2 w-full customButtonHover hover:text-primary"
                        color="#fff"
                        >
                        View</vs-button>
                      <vs-button
                        class="w-full customButtonHover hover:text-red-600"
                        type="flat"
                        icon="delete_outline"
                        icon-pack="material-icons"
                        color="#fff"
                        @click="confirmDeleteMedia(photo, 'beforePhotoContainer')"
                      >Delete</vs-button>
                    </div>
                  </div>
                  <!-- Video Element -->
                  <video v-if="isVideo(photo.url)" controls class="w-48 h-48 object-contain patient-photo">
                    <source :src="photo.url" :type="getVideoType(photo.url)">
                    Your browser does not support the video tag.
                  </video>
                  <!-- Image Element -->
                  <img v-else :src="photo.url" alt="" class="w-48 h-48 object-contain patient-photo" />
                </div>
              </div>
              <p class="font-normal mb-5 text-sm" :class="{'py-20': dragState.before}" v-else>
                <span v-if="notBasicUser">Add your pre treatment photos by pressing "+ Add"</span>
              </p>
            </div>
          </div>
          <div
            @dragover.prevent="handleDragOver('after')"
            @dragleave="handleDragLeave('after')"
            @drop.prevent="handleDrop($event, false, 'afterPhotoContainer')"
            :class="`before-photos mt-12 ${ notBasicUser ? '' : 'pointer-events-none' }`"
          >
            <div class="flex justify-between md:flex-row flex-col gap-4">
              <p>After Photos</p>
              <vs-button v-if="notBasicUser" color="primary" type="border" @click="handleAfterUploadClick" :disabled="loaderKeys.postTreatmentPhotos || disableAfterUploadBtn || dragState.after">
                <vs-icon
                  v-if="disableAfterUploadBtn"
                  icon="refresh"
                  class="animate-spin"
                ></vs-icon>
                <span v-else>+</span>
                Add
              </vs-button>
              <input
                ref="afterPhotoRef"
                type="file"
                multiple
                accept=".png,.jpg,.mp4,.mov"
                @change="uploadImage($event, false, 'afterPhotoContainer')"
                id="file-input"
                hidden
              />
            </div>
            <div v-if="loaderKeys.appointmentData || loaderKeys.postTreatmentPhotos || disableAfterUploadBtn">
              <div class="flex gap-4 flex-wrap my-4">
                <div v-for="i in 3" :key="`after-${i}`" class="animate-pulse skeleton-image w-48 h-48"></div>
              </div>
            </div>
            <div v-else class="relative">
              <!-- Drag and Drop Overlay -->
              <div
                v-if="dragState.after"
                class="drag-overlay absolute bg-white inset-0 bg-opacity-75 z-50 flex items-center justify-center rounded-lg gap-2"
              >
                <feather-icon icon="UploadIcon" class="text-primary2 text-opacity-50" />
                <p class="text-primary2 text-opacity-50 text-2xl font-bold">Drop files here</p>
              </div>
              <div id="afterPhotoContainer" class="flex gap-4 flex-wrap my-4 relative" v-if="appointmentData.postTreatmentPhotos && appointmentData.postTreatmentPhotos.length">
                <div
                  v-for="photo in appointmentData.postTreatmentPhotos"
                  :key="photo.id"
                  class="media-item-container relative w-48 h-48"
                >
                  <!-- Hover overlay with options -->
                  <div class="media-hover-overlay">
                    <div class="media-actions">
                      <vs-button
                        type="flat"
                        icon="visibility"
                        icon-pack="material-icons"
                        @click="viewMedia(photo.url)"
                        class="mr-2 w-full customButtonHover hover:text-primary"
                        color="#fff"
                        >
                        View</vs-button>
                      <vs-button
                        class="w-full customButtonHover hover:text-red-600"
                        type="flat"
                        icon="delete_outline"
                        icon-pack="material-icons"
                        color="#fff"
                        @click="confirmDeleteMedia(photo, 'afterPhotoContainer')"
                      >Delete</vs-button>
                    </div>
                  </div>
                  <!-- Check if it's a video file and render appropriately -->
                  <video v-if="isVideo(photo.url)" controls class="w-48 h-48 object-contain patient-photo">
                    <source :src="photo.url" :type="getVideoType(photo.url)">
                    Your browser does not support the video tag.
                  </video>
                  <!-- Otherwise render as image -->
                  <img v-else :src="photo.url" alt="" class="w-48 h-48 object-contain patient-photo" />
                </div>
              </div>
              <p class="font-normal mb-5 text-sm" :class="{'py-20': dragState.after}" v-else>
                <span v-if="notBasicUser">Add your post treatment photos by pressing "+ Add"</span>
              </p>
            </div>
          </div>
        </div>
        <!-- TX Notes -->
        <div class="tx-notes">
          <h5>Treatment Notes</h5>
          <div v-if="loaderKeys.appointmentData || loaderKeys.txNotes">
            <div class="mb-8">
              <div class="flex items-center gap-6 mb-8">
                <div class="animate-pulse skeleton-avatar w-12 h-12 rounded-full"></div>
                <div>
                  <div class="animate-pulse skeleton-line w-48 h-5 mb-2"></div>
                  <div class="animate-pulse skeleton-line w-32 h-4"></div>
                </div>
              </div>
              <div class="animate-pulse skeleton-line w-1/2 h-5 mb-3"></div>
              <div class="animate-pulse skeleton-line w-1/3 h-5 mb-3"></div>
              <div class="animate-pulse skeleton-line w-3/4 h-5 mb-4"></div>
            </div>
          </div>
          <div v-else>
            <p class="mb-12 font-normal text-sm" v-if="appointmentData.notes && !appointmentData.notes.length">Add your treatment notes by posting a comment below.</p>
            <div v-if="appointmentData && appointmentData.notes && appointmentData.notes.length">
              <div
                v-for="(note, index) in appointmentData.notes"
                :key="note._id"
              >
              <div>
                <div class="flex items-center gap-6">
                  <img
                    v-if="note.author.profileImage"
                    :src="note.author.profileImage"
                    alt=""
                    class="w-12 h-12 object-contain rounded-full patient-photo">
                  <vs-avatar v-else size="42px" :text="note.author.firstName" />
                  <p class="leading"> <span class="font-medium">
                    {{ note.author.firstName }} {{ note.author.lastName }}
                  </span> <br> <span
                      class="text-sm text-gray-600">{{ moment(note.updatedAt).format("LLL") }}</span></p>
                </div>
                <p class="text-base mt-6 preserveLine font-normal" v-html="note.additionalNote.note"  />
                <div class="flex gap-2 md:items-center items-start md:flex-row flex-col">
                  <div v-if="note.additionalNote.images.length" class="flex gap-2 md:items-center items-start md:flex-row flex-col">
                    <div
                      v-for="photo in note.additionalNote.images"
                      :key="photo._id"
                    >
                      <!-- Check if it's a video file and render appropriately -->
                      <video v-if="isVideo(photo.url)" controls class="md:w-48 md:h-48 w-32 h-32 object-contain patient-photo">
                        <source :src="photo.url" :type="getVideoType(photo.url)">
                        Your browser does not support the video tag.
                      </video>
                      <!-- Otherwise render as image -->
                      <img v-else :src="photo.url" alt="" class="md:w-48 md:h-48 w-32 h-32 object-contain patient-photo" />
                    </div>
                  </div>
                  <div v-if="note.proceduralNote && note.proceduralNote.length" class="flex gap-2 md:items-center items-start md:flex-row flex-col">
                    <div
                      v-for="photo in note.proceduralNote"
                      :key="photo._id"
                      class="relative"
                    >
                      <div
                        class="absolute text-white bg-primary2 rounded-full px-3"
                        :style="{
                          left: `${photo.xCoordinate * 100}%`,
                          top: `${photo.yCoordinate * 100}%`,
                        }"
                      >
                        {{ photo.addedOrder }}
                      </div>
                      <img :src="photo.part.image" alt="" class="md:w-48 md:h-48 w-32 h-32 object-contain patient-photo" />
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-8" v-if="appointmentData.notes && index !== appointmentData.notes.length - 1" />
              </div>
            </div>
          </div>
          <div
            v-if="notBasicUser"
            class="mt-10 post-comment-wrapper"
            @dragover.prevent="handleDragOver('txNotes')"
            @dragleave="handleDragLeave('txNotes')"
            @drop.prevent="handleDropNoteFiles($event)"
          >
            <vs-textarea
              height="95px"
              placeholder="Write your treatment notes here..."
              v-model="newNote"
              class="border-none customTextAreaClass"
              :disabled="loaderKeys.txNotes || disableCommentBtn"
            ></vs-textarea>
            <!-- Drag and Drop Overlay -->
            <div
              v-if="dragState.txNotes"
              class="drag-overlay absolute inset-0 bg-opacity-50 z-50 flex items-center justify-center rounded-lg gap-2"
            >
              <feather-icon icon="UploadIcon" class="text-primary2 text-opacity-50" />
              <p class="text-primary2 text-opacity-50 text-2xl font-bold">Drop files here</p>
            </div>
            <div v-if="preNoteImages && preNoteImages.target.files.length">
              <div
                v-for="(image, index) in preNoteImages.target.files"
                :key="index"
                class="p-2"
              >
              <div class="customBorder rounded-lg inline-flex items-center gap-2 px-2 py-1" :class="{'pointer-events-none cursor-wait': loaderKeys.txNotes || disableCommentBtn}">
                <p>{{ image.name }}</p>
                <vs-button
                  icon="delete_outline"
                  size="small"
                  color="danger"
                  type="border"
                  :disabled="loaderKeys.txNotes || disableCommentBtn"
                  @click="handleRemoveNoteFile(index)"
                />
              </div>
              </div>
            </div>
            <div class="btn-post flex items-center justify-between">
              <el-tooltip content="Upload Image/Video" placement="top">
                <label class="cursor-pointer" :class="{'pointer-events-none cursor-wait': loaderKeys.txNotes || disableCommentBtn}">
                  <vs-icon icon="image" size="24px"></vs-icon>
                  <input
                    accept=".png,.jpg,.mp4,.mov"
                    type="file"
                    id="dropzone-file"
                    hidden
                    multiple
                    @change="handleStoreNoteFiles($event)"/>
                </label>
              </el-tooltip>
              <vs-button color="primary" type="border" @click="postComment" :disabled="!newNote.length || loaderKeys.txNotes || disableCommentBtn">
                <vs-icon
                  v-if="disableCommentBtn"
                  icon="refresh"
                  class="animate-spin"
                ></vs-icon>
                Post Comment
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Treatment Details -->

    <!-- Side Drawer -->
    <vs-sidebar position-right click-not-close parent="body" class="sidebarx relative overflow-auto searchProductSidebar" v-model="producstDrawer">
      <div class="headerClass space-y-3">
        <p class="text-xl font-semibold px-4 pt-4">Search Products</p>
        <vs-divider></vs-divider>
        <vs-input
          placeholder="Search products"
          class="w-full pb-4 px-4"
          v-model="searchProduct"
          @input="customFilter"
        />
        <vs-divider v-if="appointmentData.treatmentPlans && appointmentData.treatmentPlans.length"></vs-divider>
        <div v-if="appointmentData.treatmentPlans && appointmentData.treatmentPlans.length" class="px-4 space-y-2 pb-4 addedProductsHeight">
            <p class="font-semibold">Added Products</p>
            <div v-for="treatment in appointmentData.treatmentPlans" :key="treatment._id">
              <div @click="showAddTreatment(treatment, true)" class="addedProductsBorder p-2 cursor-pointer flex items-center justify-between hover:bg-opacity-25">
                <div>
                  <p><strong>{{ treatment.productName }}</strong></p>
                  <div v-for="(part, index) in treatment.parts" :key="index">
                    <p>{{ part.area }} &#183; {{ part.amount }} {{ treatment.unit }}</p>
                  </div>
                </div>
                <vs-icon icon="arrow_forward_ios" class="float-right"/>
              </div>
            </div>
          </div>
      </div>
      <div v-if="products && products.length > 0" class="scrollableContent">
        <div v-for="item in products" :key="item._id">
          <vs-button v-if="!isTreatmentPlanExisting(item)" type="flat" :class="`mb-2 w-full ${isTreatmentPlanExisting(item) ? 'text-primary font-medium' : 'gap-0' }`" @click="showAddTreatment(item, isTreatmentPlanExisting(item))">
            {{ item.name }}
            <vs-icon icon="arrow_forward_ios" />
          </vs-button>
        </div>
      </div>
      <div v-else class="flex flex-col justify-center items-center h-full">
        <p class="text-lg font-semibold">No products found</p>
      </div>
      <div class="footerClass flex flex-col gap-3 p-4" slot="footer">
        <vs-button @click.prevent="handleSaveTreatmentProducts" :disabled="isSavingProducts || loaderKeys.patientProducts">
          <vs-icon
            v-if="isSavingProducts || loaderKeys.patientProducts"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          Save
        </vs-button>
        <vs-button class="w-full" type="border" @click.prevent="handleExitProductsSidebar">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>

    <vs-sidebar position-right click-not-close parent="body" class="sidebarx relative overflow-auto addProductSidebar" v-model="showAddTreatmentPopup">
      <div class="headerClass flex items-center justify-between px-4">
        <p class="text-xl font-semibold py-4">Add Products</p>
        <vs-button v-if="addTreatmentData.isUpdate" type="line" color="danger" @click="confirmDeleteTreatmentPlan">Delete</vs-button>
      </div>
      <div class="px-4 pt-4 space-y-2 scrollableContent">
        <div class="relative">
          <vs-input
            label="Product Name"
            class="w-full relative"
            v-model="addTreatmentData.name"
            disabled
          />
          <vs-button class="customButtonFloat px-2" color="white" text-color="#0E4333" size="small" @click="handleGoBackToProducts(true)">Change</vs-button>
        </div>
        <div v-for="(part, index) in addTreatmentData.parts" :key="index" class="flex flex-col gap-2">
          <vs-select
            label="Area"
            autocomplete
            class="w-full"
            placeholder="Select Area"
            v-model="part.area"
          >
            <vs-select-item :key="0" :value="undefined" :disabled="true" v-show="false" />
            <vs-select-item
              v-for="item in addTreatmentData.applicableAreas"
              :key="item"
              :value="item"
              :text="item"
              :clearable="false"
            />
          </vs-select>
          <div class="flex items-end gap-2 pb-2">
            <vs-input
              class="w-full"
              data-vv-scope="appointment"
              data-vv-validate-on="blur"
              v-validate="'required|min:1|numeric'"
              required
              type="number"
              :name="`amount${index + 1}`"
              min="1"
              :label="addTreatmentData.unit"
              v-model="part.amount"
              :danger="errors.first(`appointment.amount${index+1}`) ? true : false"
              :danger-text="errors.first(`appointment.amount${index+1}`)"
            />
            <vs-button v-if="addTreatmentData.parts && addTreatmentData.parts.length > 1" icon="delete_outline" type="border" class="p-2" color="danger" @click="handleRemovePart(index)"></vs-button>
          </div>
        </div>
        <vs-button icon="add" type="flat" @click="handleAddAnotherArea">Add another area</vs-button>
      </div>
      <div class="p-4 flex flex-col gap-3" slot="footer">
        <vs-button @click.prevent="handleSaveProducts" :disabled="checkAreaAndAmountValidity">
          {{ addTreatmentData.isUpdate ? "Update Product" : "Add Product" }}
        </vs-button>
        <vs-button type="border" @click.prevent="handleGoBackToProducts()">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>

    <SideDrawerEditPatient
      :patient=patientDetails
      @refetchPatientDetail=refetchPatientDetail
      ref="sideDrawerEditPatient"
    />

  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions } from 'vuex';
import SideDrawerEditPatient from './SideDrawerEditPatient.vue'
import SendAftercareDocument from './SendAftercareEmail.vue'
import GenerateAftercare from './GenerateAftercare.vue'
import loader from 'sass-loader';

export default {
  name: "ProductDetails",
  components: {
    SideDrawerEditPatient,
    SendAftercareDocument,
    GenerateAftercare,
  },
  props: {
    backRoute: {
      type: String,
    }
  },
  data() {
    return {
      producstDrawer: false,
      products: [],
      treatmentNames: [],
      isScripted: false,
      appointmentData: {
        createdAt: "",
        nurse: {},
        doctor: {},
        patient: {},
        appointments: {},
      },
      patientDetails: {
        email: "",
        dateOfBirth: "",
        profileImage: "",
        allergies: [],
        regularMedications: [],
        previousMedicalDiagnoses: [],
        address: "",
        lastName: "",
        contactNumber: "",
        firstName: "",
        gender: "",
        fullName: "",
      },
      searchProduct: "",
      newNote: '',
      preNoteImages: null,
      postNoteImages: [],
      showAddTreatmentPopup: false,
      addTreatmentData: {
        parts: [{ area: undefined, amount: undefined }]
      },
      isSavingProducts: false,
      loaderKeys: {
        appointmentData: false, // Defaults to load all components
        patientHeaderTreatmentDetails: false,
        patientTreatmentDetails: false,
        consentForm: false,
        patientProducts: false,
        aftercare: false,
        patientDetails: false,
        preTreatmentPhotos: false,
        postTreatmentPhotos: false,
        txNotes: false,
      },
      disableCommentBtn: false,
      disableBeforeUploadBtn: false,
      disableAfterUploadBtn: false,
      dragState: {
        before: false,
        after: false,
        txNotes: false
      },
      currentTreatmentProduct: null,
      updatedTreatmentPlans: [],
      allowedTypes: {
        'image/png': true,
        'image/jpeg': true,
        'video/mp4': true,
        'video/quicktime': true,
      },
    }
  },
  methods: {
    ...mapActions("nurse", [
      "fetchNurseTreatmentPlans",
    ]),
    ...mapActions("doctor", [
      "updateAppointmentTreatmentPlans",
    ]),
    ...mapActions("patient", ["patientDetail2"]),
    ...mapActions("appointment", [
      "getAppointmentDetailsV2",
      "saveAppointmentNote",
      "uploadMultipleFiles",
      "deleteAppointmentFile",
      "uploadNoteFiles",
      ]),
    moment(date) {
      return moment(date);
    },
    updateAftercareDetails(data) {
      this.appointmentData.aftercareDetails = data;
    },
    async fetchAppointmentDetails(loader = "appointmentData") {
      this.loaderKeys[loader] = true;
      try {
        if (!this.$route.query.appointmentId) return;
        const res = await this.getAppointmentDetailsV2(this.$route.query.appointmentId);
        if (res.status === 200) {
          this.loaderKeys[loader] = false;
          this.appointmentData = res.data.data;
          const newTreatmentPlans = [];
          if (this.appointmentData.treatmentPlans.length) {
            const temp = this.appointmentData.treatmentPlans.reduce((acc, item) => {
              const { productId, area, amount } = item;

              if (!acc[productId]) {
                  acc[productId] = {
                      ...item,
                      parts: [] // Stores area and amount
                  };
              }

              acc[productId].parts.push({ area, amount });

              return acc;
            }, {});
            newTreatmentPlans.push(Object.values(temp));
          }
          this.appointmentData.treatmentPlans = newTreatmentPlans.flat();
          this.updatedTreatmentPlans = newTreatmentPlans.flat();
        }
      } catch (error) {
        this.loaderKeys[loader] = false;
        console.error(error);
      }
    },
    isVideo(url) {
      const videoExtensions = ['.mp4', '.mov', '.avi', '.wmv', '.flv', '.webm', '.mkv'];
      return videoExtensions.some(ext => url.toLowerCase().endsWith(ext));
    },
    getVideoType(url) {
      // Get the appropriate MIME type based on extension
      if (url.toLowerCase().endsWith('.mp4')) return 'video/mp4';
      if (url.toLowerCase().endsWith('.mov')) return 'video/quicktime';
      if (url.toLowerCase().endsWith('.webm')) return 'video/webm';
      if (url.toLowerCase().endsWith('.avi')) return 'video/x-msvideo';
      if (url.toLowerCase().endsWith('.wmv')) return 'video/x-ms-wmv';
      if (url.toLowerCase().endsWith('.flv')) return 'video/x-flv';
      if (url.toLowerCase().endsWith('.mkv')) return 'video/x-matroska';

      // Default
      return 'video/mp4';
    },
    async getPatientDetail() {
      this.loaderKeys.patientDetails = true;
      try {
        const res = await this.patientDetail2(this.$route.params.patientId);
        if (res.status === 200) {
          this.loaderKeys.patientDetails = false;
          this.patientDetails = res.data.data;
        }
      } catch (error) {
        this.loaderKeys.patientDetails = false;
        console.error(error);
      }
    },
    async getNurseTreatmentPlans() {
      try {
        const payload = {
          clinicId: this.appointmentData.clinicDetail.clinicId,
          page: 1,
          limit: 500,
        };
        if (this.searchProduct) {
          payload.name = this.searchProduct;
        }
        const res = await this.fetchNurseTreatmentPlans(payload);
        if (res.status === 200) {
          this.products = res.data.data.docs;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async postComment() {
      if (!this.newNote.length > 0) {
        return;
      }
      this.disableCommentBtn = true;
      try {
        await this.handleUploadNoteFiles();
        await this.saveAppointmentNote({
          appointmentId: this.appointmentData.appointmentId,
          additionalNote: {
            images: this.postNoteImages,
            note: this.newNote
          }
        })
        this.newNote = '';
        this.preNoteImages = null;
        this.postNoteImages = [];
        this.fetchAppointmentDetails("txNotes");
        this.disableCommentBtn = false;
      } catch (error) {
        this.disableCommentBtn = false;
        console.error(error);
      }
    },
    customFilter: _.debounce(function (data) {
      this.searchProduct = data;
      this.getNurseTreatmentPlans();
    }, 500),
    editPatientDetailsDrawer() {
      this.$refs.sideDrawerEditPatient.openSideDrawer();
    },
    async handleFileUploads(event) {
      const files = event.target.files;
      const validFiles = [];
      let invalidFiles = 0;
      let hasInvalidExtension = false;
      // Array to track promises for video duration checks
      const promises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (!this.allowedTypes[file.type]) {
          hasInvalidExtension = true;
          continue;
        }

        // If it's a video file (mp4, mov)
        if (file.type === "video/mp4" || file.type === "video/quicktime") {
          const promise = await new Promise((resolve) => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
              URL.revokeObjectURL(video.src);

              if (video.duration <= 60) {
                validFiles.push(file);
                resolve();
              } else {
                invalidFiles++;
                resolve();
              }
            };

            video.src = URL.createObjectURL(file);
          });

          promises.push(promise);
        } else if (file.type === "image/png" || file.type === "image/jpeg") {
          // For image files
          validFiles.push(file);
        } else {
          hasInvalidExtension = true;
        }
      }

      return {
        validFiles,
        invalidFiles,
        promises,
        hasInvalidExtension,
      }
    },
    async uploadImage(event, isBeforePhoto, loadingClass = "") {
      let loaderName = "";
      try {
        if (isBeforePhoto) {
          loaderName = "preTreatmentPhotos";
          this.disableBeforeUploadBtn = true;
        } else {
          loaderName = "postTreatmentPhotos";
          this.disableAfterUploadBtn = true;
        }
        if (!event.target.files.length) {
          this.disableBeforeUploadBtn = false;
          this.disableAfterUploadBtn = false;
          return;
        }

        const { validFiles, invalidFiles, promises, hasInvalidExtension } = await this.handleFileUploads(event);

        await Promise.all(promises).then(async () => {
          if (hasInvalidExtension) {
            this.disableBeforeUploadBtn = false;
            this.disableAfterUploadBtn = false;
            this.$vs.notify({
              title: "Upload Failed",
              text: "Invalid file type. Please upload a valid image or video file.",
              color: "danger",
            });
            return;
          }

          if (invalidFiles > 0) {
            this.disableBeforeUploadBtn = false;
            this.disableAfterUploadBtn = false;
            this.$vs.notify({
              title: "Upload Failed",
              text: `${invalidFiles} video(s) exceed the 60-second limit. Please upload a video less than or equal to 60 seconds.`,
              color: "danger",
            });
            return;
          }

          if (validFiles.length) {
            const dataTransfer = new DataTransfer();
            validFiles.forEach(file => dataTransfer.items.add(file));

            const newEvent = {
              ...event,
              target: {
                ...event.target,
                files: dataTransfer.files
              }
            }
            const res = await this.uploadMultipleFiles({
              event: newEvent,
              isBeforePhoto: isBeforePhoto,
              appointmentId: this.appointmentData.appointmentId,
            });
            if (res.status === 200) {
              this.disableBeforeUploadBtn = false;
              this.disableAfterUploadBtn = false;
              await this.fetchAppointmentDetails(loaderName);
            }
          }
        });
      } catch (error) {
        console.error(error);
        this.disableBeforeUploadBtn = false;
        this.disableAfterUploadBtn = false;
        if (error && error.response.status === 415) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            color: "danger",
          });
        } else {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            color: "danger",
          });
        }
      }
    },
    handleBeforeUploadClick() {
      this.$refs.beforePhotoRef.click();
    },
    handleAfterUploadClick() {
      this.$refs.afterPhotoRef.click();
    },
    viewMedia(url) {
      window.open(url, '_blank');
    },
    confirmDeleteMedia(media, photoClass = "") {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Deletion',
        text: 'Are you sure you want to delete this media? This action cannot be undone.',
        accept: () => this.deleteMedia(media, photoClass),
        acceptText: 'Delete',
        cancelText: 'Cancel'
      });
    },
    async deleteMedia(media, photoClass = "") {
      if (photoClass === "beforePhotoContainer") {
        this.loaderKeys.preTreatmentPhotos = true;
      }
      if (photoClass === "afterPhotoContainer") {
        this.loaderKeys.postTreatmentPhotos = true;
      }
      try {
        if (!media.url) {
          this.loaderKeys.preTreatmentPhotos = false;
          this.loaderKeys.postTreatmentPhotos = false;
          this.$vs.notify({
            title: 'Error',
            text: 'No media url found',
            color: 'danger'
          });
          return;
        }

        const payload = {
          imageUrl: media.url,
          appointmentId: this.appointmentData.appointmentId,
          photoId: media.id
        };

        const res = await this.deleteAppointmentFile(payload);
        if (res.status === 200) {
          if (photoClass === "beforePhotoContainer") {
            const index = this.appointmentData.preTreatmentPhotos.findIndex(photo => photo.id === media.id);
            this.appointmentData.preTreatmentPhotos.splice(index, 1);
            this.loaderKeys.preTreatmentPhotos = false;
          }
          if (photoClass === "afterPhotoContainer") {
            const index = this.appointmentData.postTreatmentPhotos.findIndex(photo => photo.id === media.id);
            this.appointmentData.postTreatmentPhotos.splice(index, 1);
            this.loaderKeys.postTreatmentPhotos = false;
          }
        }

        this.$vs.notify({
          title: 'Success',
          text: 'Media deleted successfully',
          color: 'success'
        });
      } catch (error) {
        this.loaderKeys.preTreatmentPhotos = false;
        this.loaderKeys.postTreatmentPhotos = false;
        console.error('Error deleting media:', error);
        this.$vs.notify({
          title: 'Error',
          text: 'Failed to delete media',
          color: 'danger'
        });
      }
    },
    async refetchPatientDetail() {
      await this.getPatientDetail();
      await this.fetchAppointmentDetails("patientDetails");
    },
    async handleStoreNoteFiles(event) {
      try {
      // Create a DataTransfer object to manipulate files
      const dataTransfer = new DataTransfer();

      if (event.target.files.length) {
        const hasInvalidExtension = Array.from(event.target.files).some(file => !this.allowedTypes[file.type]);
        if (hasInvalidExtension) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Invalid file type. Please upload a valid image or video file.",
            color: "danger",
          });
          return;
        }
      }

      // If we already have files stored, add them to our DataTransfer object first
      if (this.preNoteImages && this.preNoteImages.target.files.length) {
        // Add existing files
        Array.from(this.preNoteImages.target.files).forEach(file => {
          dataTransfer.items.add(file);
        });
      }

      // Add the new files
      if (event.target.files.length) {
        Array.from(event.target.files).forEach(file => {
          dataTransfer.items.add(file);
        });
      }

      const tempFiles = {
          ...event,
          target: {
            ...event.target,
            files: dataTransfer.files
          }
        }

        const { invalidFiles, promises } = await this.handleFileUploads(tempFiles);
        await Promise.all(promises).then(async () => {
          if (invalidFiles > 0) {
            this.$vs.notify({
              title: "Upload Failed",
              text: `${invalidFiles} video(s) exceed the 60-second limit. Please upload a video less than or equal to 60 seconds.`,
              color: "danger",
            });
            throw new Error(`${invalidFiles} video(s) exceed the 60-second limit. Please upload a video less than or equal to 60 seconds.`)
          }

      if (!this.preNoteImages) {
        // If this is the first upload, keep all the original event data
        this.preNoteImages = {
          ...event,
          target: {
            ...event.target,
            files: dataTransfer.files
          }
        };
      } else {
        // If we already have data, keep the existing event structure and just update the files
        this.preNoteImages = {
          ...this.preNoteImages,
          target: {
            ...this.preNoteImages.target,
            files: dataTransfer.files
          }
        };
      }
    });
      } catch (error) {
        console.error(error);
      }
    },
    handleRemoveNoteFile(index) {
      // Create a new DataTransfer object
      const dataTransfer = new DataTransfer();

      // Add all files except the one at the specified index
      Array.from(this.preNoteImages.target.files).forEach((file, i) => {
        if (i !== index) {
          dataTransfer.items.add(file);
        }
      });

      // Update preNoteImages with the new FileList but preserve all other event data
      this.preNoteImages = {
        ...this.preNoteImages,
        target: {
          ...this.preNoteImages.target,
          files: dataTransfer.files
        }
      };
    },
    async handleUploadNoteFiles() {
      if (!this.preNoteImages) return; // No files to upload

      const { validFiles, invalidFiles, promises, hasInvalidExtension } = await this.handleFileUploads(this.preNoteImages);
      await Promise.all(promises).then(async () => {
        if (hasInvalidExtension) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Invalid file type. Please upload a valid image or video file.",
            color: "danger",
          });
          return;
        }

        if (invalidFiles > 0) {
          this.$vs.notify({
            title: "Upload Failed",
            text: `${invalidFiles} video(s) exceed the 60-second limit. Please upload a video less than or equal to 60 seconds.`,
            color: "danger",
          });
          throw new Error(`${invalidFiles} video(s) exceed the 60-second limit. Please upload a video less than or equal to 60 seconds.`)
        }

        if (validFiles.length) {
          const dataTransfer = new DataTransfer();
          validFiles.forEach((file) => dataTransfer.items.add(file));

          const newEvent = {
            ...this.preNoteImages,
            target: {
              ...this.preNoteImages.target,
              files: dataTransfer.files
            }
          }

          const res = await this.uploadNoteFiles({
            event: newEvent,
          });
          if (res.status === 200) {
            this.postNoteImages = res.data.data;
          }
        }
      })
    },
    showAddTreatment(data, isUpdate = false) {
      if (isUpdate && this.appointmentData.treatmentPlans.length) {
        const updateData = this.appointmentData.treatmentPlans.find((treatment) => treatment.productId === data.productId);
        this.addTreatmentData = {
          ..._.cloneDeep(updateData),
          name: updateData.productName,
          _id: updateData.productId,
          isUpdate,
        }
        this.currentTreatmentProduct = {};
      } else {
        this.addTreatmentData = {
          ...data,
          isUpdate,
          parts: [{ area: undefined, amount: undefined }],
        };
      }
      this.producstDrawer = false;
      this.$nextTick(() => {
        this.showAddTreatmentPopup = true;
      });
    },
    handleGoBackToProducts(isChangeClicked = false) {
      this.showAddTreatmentPopup = false;
      this.$nextTick(() => {
        if (isChangeClicked) {
          this.currentTreatmentProduct = this.addTreatmentData;
        } else {
          this.currentTreatmentProduct = {};
        }
        this.addTreatmentData = {};
        this.producstDrawer = true;
      });
    },
    handleSaveProducts() {
      if (this.addTreatmentData.isUpdate) {
        const existingProduct = this.appointmentData.treatmentPlans.find((treatment) => treatment.productId === this.addTreatmentData._id);
        if (existingProduct) {
          this.appointmentData.treatmentPlans = this.appointmentData.treatmentPlans.map((treatment) => {
            if (treatment.productId === this.addTreatmentData._id) {
              return {
                ...treatment,
                area: this.addTreatmentData.area,
                amount: this.addTreatmentData.amount,
                parts: this.addTreatmentData.parts,
              }
            }

            return treatment;
          });
        }
      } else {
        const newProduct = {
          area: this.addTreatmentData.area,
          amount: this.addTreatmentData.amount,
          productName: this.addTreatmentData.name,
          productId: this.addTreatmentData._id,
          unit: this.addTreatmentData.unit,
          applicableAreas: this.addTreatmentData.applicableAreas,
          parts: this.addTreatmentData.parts,
        };

        if (this.currentTreatmentProduct && this.currentTreatmentProduct.productId) {
          const existingProduct = this.appointmentData.treatmentPlans.findIndex(treatment => treatment.productId === this.currentTreatmentProduct.productId);
          this.appointmentData.treatmentPlans.splice(existingProduct, 1, newProduct);
        } else {
          this.appointmentData.treatmentPlans.push(newProduct);
        }
      }
      this.handleGoBackToProducts();
    },
    isTreatmentPlanExisting(data) {
      if (!this.appointmentData.treatmentPlans.length) return false;

      return this.appointmentData.treatmentPlans.some((treatment) => treatment.productId === data._id);
    },
    handleExitProductsSidebar() {
      this.producstDrawer = false;
      this.currentTreatmentProduct = {};
      this.addTreatmentData = {};
      this.appointmentData.treatmentPlans = [...this.updatedTreatmentPlans];
    },
    async handleSaveTreatmentProducts() {
      try {
        this.isSavingProducts = true;
        const treatmentPlansPayload = [];
        this.appointmentData.treatmentPlans.map((treatment) => {
          let temp = {...treatment};
          delete temp.parts;
          if (!treatment.parts.length === 1) return;

          treatment.parts.map((part) => {
            treatmentPlansPayload.push({
              ...temp,
              area: part.area,
              amount: part.amount,
            })
          })
        });
        // Check for duplicate parts first
        if (treatmentPlansPayload.length) {
          const duplicateParts = treatmentPlansPayload.filter((part, index) => treatmentPlansPayload.findIndex(p => p.area === part.area && p.productId === part.productId) !== index);
          if (duplicateParts.length) {
            this.isSavingProducts = false;
            this.$vs.notify({
              title: "Duplicate Area Added",
              text: "You've selected the same treatment area more than once. Please adjust before continuing.",
              color: "danger",
            });
            return;
          }
        }
        const res = await this.updateAppointmentTreatmentPlans({
          appointmentId: this.appointmentData.appointmentId,
          treatmentPlans: treatmentPlansPayload,
        });
        if (res.status === 200) {
          this.isSavingProducts = false;
          await this.fetchAppointmentDetails("patientProducts");
          this.handleExitProductsSidebar();
          this.$vs.notify({
            title: "Success",
            text: "Treatment products updated successfully",
            color: "success",
          });
        }
      } catch (error) {
        console.error(error);
        this.isSavingProducts = false;
        this.$vs.notify({
          title: "Error",
          text: "Failed to update treatment products. Try again!",
          color: "danger",
        });
      }
    },
    handleDragOver(section) {
      this.dragState[section] = true;
    },
    handleDragLeave(section) {
      this.dragState[section] = false;
    },
    async handleDrop(event, isBeforePhoto, loadingClass) {
      this.dragState[isBeforePhoto ? 'before' : 'after'] = false;

      await this.uploadImage({
        target: event.dataTransfer,
        preventDefault: () => {}
      }, isBeforePhoto, loadingClass);
    },
    async handleDropNoteFiles(event) {
      this.dragState.txNotes = false;

      const fileEvent = {
        target: {
          files: event.dataTransfer.files
        }
      };

      this.handleStoreNoteFiles(fileEvent);
    },
    handleAddAnotherArea() {
      this.addTreatmentData.parts.push({
        area: undefined,
        amount: undefined,
      });
    },
    handleRemovePart(index) {
      this.addTreatmentData.parts.splice(index, 1);
    },
    confirmDeleteTreatmentPlan() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete Product from Treatment Plan?',
        text: 'Are you sure you want to remove this product?',
        accept: () => this.deleteTreatmentPlan(),
        acceptText: 'Delete',
        cancelText: 'Cancel'
      });
    },
    async deleteTreatmentPlan() {
      this.appointmentData.treatmentPlans = this.appointmentData.treatmentPlans.filter((treatment) => treatment.productId !== this.addTreatmentData.productId);
      this.handleGoBackToProducts();
    },
  },
  async created() {
    await this.getPatientDetail();
    await this.fetchAppointmentDetails();
    await this.getNurseTreatmentPlans();
  },
  watch: {
    producstDrawer(newVal) {
      if (newVal) {
        // When sidebar is active, disable scroll on body
        document.body.style.overflow = "hidden";
      } else {
        // When sidebar is inactive, re-enable scroll
        document.body.style.overflow = "";
      }
    },
    showAddTreatmentPopup(newVal) {
      if (newVal) {
        // When sidebar is active, disable scroll on body
        document.body.style.overflow = "hidden";
      } else {
        // When sidebar is inactive, re-enable scroll
        document.body.style.overflow = "";
      }
    },
  },
  computed: {
    notBasicUser() {
      return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).userType !== "basic"
    },
    showSendAftercareEmail() {
      return this.appointmentData.consent ? this.appointmentData.consent.pdf : null && JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).doctorEMRClinics.length > 0 && this.aftercareDetails.afterCareSectionShow;
    },
    isDoctorEMR(){
      return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).doctorEMRClinics.length
    },
    isLoginUserEqualToTreatmentOwner(){
      const userInfo = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`))
      if(this.appointmentData.treatmentOwnerDetail){
        return this.appointmentData.treatmentOwnerDetail.id === userInfo._id
      }
      return userInfo.doctorEMRClinics.length
    },
    checkAreaAndAmountValidity() {
      if (!this.addTreatmentData || !Array.isArray(this.addTreatmentData.parts)) return false;
      return !this.addTreatmentData.parts.every(part =>
        part.area && part.amount !== undefined && part.amount > 0
      );
    },
  },
}
</script>

<style lang="scss">
$font-color-primary: #0E4333;
$font-color-secondary: #262928;
$border-color: #E3DDD7;
$skeleton-base-color: #ebebeb;
$skeleton-highlight-color: #eeeeee;

.patient-header-details {
  line-height: 1.8rem;
  color: $font-color-secondary;
}

.treatment-details-wrapper {
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  column-gap: 2rem;
  line-height: 1.8rem;
  color: $font-color-secondary;

  @media screen and (min-width:1024px) {
    flex-direction: row;
  }

  > div {
    @media screen and (min-width:1024px){
      &:first-child{
        width: 40%;
      }
      &:last-child{
        width: 60%;
      }
    }
    > div {
      border: 1px solid $border-color;
      box-shadow: 0px 0.94px 1.88px 0px #00000014;
      border-radius: 8px;
      padding: 1.5rem 2rem;
      margin-bottom: 1.5rem;

      .patient-photo {
        border: 1px solid $border-color;
        border-radius: 8px;
      }
      .patient-details {
        h4 {
          font-weight: 800;
          font-size: 1.5rem;
          line-height: 2rem;
        }
        p {
          font-size: 1rem;
          line-height: 1.5rem;
          color: $font-color-primary;
        }
      }
      .patient-meds {
        >div {
          margin-bottom: 1rem;
        }
        p {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1rem;
        }
        li {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
        ul {
          list-style: inside;
          padding-left: 1rem;
          margin-top: .5rem;
        }
      }

      &.patient-treatment-details,
      &.patient-treatment-products {

        p {
          font-weight: 400;
          font-size: 0.875rem;
          strong {
            font-weight: 500;
            margin-right: .5rem;
          }
        }
      }
      &.patient-treatment-photos {
        p {
          color: $font-color-primary;
          font-weight: 600;
          font-size: 1rem;
        }
      }
      &.tx-notes {
        .leading {
          line-height: 1.3;
        }
        .cke_bottom,
        .cke_top {
          background-color: transparent;
        }
        .cke_chrome {
          border: none;
          margin: 1rem;
        }
        .post-comment-wrapper {
          border: 1px solid $border-color;
          border-radius: 1.25rem;

          .btn-post {
            border-top: 1px solid $border-color;
            padding: 1rem;
            text-align: right;
          }
        }
      }
      &.patient-aftercare{
        button{
          border: 1px solid $border-color;
          box-shadow: 0px 0.94px 1.88px 0px #00000014;
          color: $font-color-primary!important;
          background-color: #fff!important;
          &:hover{
            background: rgba(var(--vs-primary), .08) !important;
          }
        }
        .aftercare-btn-responsive{
          @media screen and (min-width: 600px) and (max-width: 1024px),
                 screen and (min-width: 1450px) {
              display: flex;
          }
        }
      }
      hr {
        border: 1px solid $border-color
      }
      h5 {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;
        color: $font-color-primary;
        margin-bottom: 1.5rem;
      }

    }
  }
}

.treatment-history-doc-emr{
  button {
    border: 1px solid $border-color;
    box-shadow: 0px 0.94px 1.88px 0px #00000014;
    color: $font-color-primary;
    font-weight: 600;
    line-height: 1.25rem;
  }
}

.vs-sidebar--background {
  z-index: 52000 !important;
}

.sidebarEditPatientDetailsDrawer {
   .vs-sidebar-position-right {
    max-width: 350px;
  }
}


.vs-sidebar.vs-sidebar-position-right {
  z-index: 53000;
}

.sidebarx .vs-sidebar-primary {
  max-width: none;
}

@media (min-width: 768px) {
  .sidebarx .vs-sidebar-primary {
    max-width: 350px;
  }
}

.preserveLine {
  white-space: pre-line;
}

.pdf-container {
  width: 100%;
  margin: 0 auto;
}
.pdf-viewer {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}


.media-item-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;

  /* Ensure the video controls don't appear on top of our overlay */
  video::-webkit-media-controls {
    z-index: 1;
  }

  /* Hide the overlay by default */
  .media-hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10;

    transform: translateY(100%); /* Start from bottom (outside the container) */
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  /* Center the action buttons */
  .media-actions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }

  /* Show overlay on hover */
  &:hover .media-hover-overlay {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Make the patient photo take full space in container */
.patient-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customButtonHover {
  &:hover {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
}

.customTextAreaClass > .vs-textarea {
  max-height: 110px;
  min-height: 110px;
  resize: none !important;
  &:focus {
    resize: none !important;
  }
}

.customBorder {
  border: 1px solid $border-color;
}

.customButtonFloat {
  position: absolute;
  right: 6px;
  top: 26px;
  border: 1px solid $border-color;
}

.scrollableContent {
  overflow-y: auto;
  padding: 0.875rem 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.footerClass {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}

.headerClass {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.searchProductSidebar .vs-sidebar-primary,
.addProductSidebar .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}
.searchProductSidebar .vs-sidebar--items,
.addProductSidebar .vs-sidebar--items {
  display: flex;
  flex-direction: column;
  padding: 0;
}

@media (min-width: 768px) {
  .searchProductSidebar .vs-sidebar-primary {
    max-width: 350px;
  }
  .addProductSidebar .vs-sidebar-primary {
    max-width: 350px;
  }
}

@media (min-width: 1024px) {
  .searchProductSidebar .vs-sidebar-primary {
    max-width: 350px;
  }
  .addProductSidebar .vs-sidebar-primary {
    max-width: 350px;
  }
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton-container {
  border: 1px solid $border-color;
  box-shadow: 0px 0.94px 1.88px 0px #00000014;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  margin-bottom: 1.5rem;
}

.animate-pulse  {
  background: linear-gradient(90deg, $skeleton-base-color 25%, $skeleton-highlight-color 50%, $skeleton-base-color 75%);
  background-size: 200px 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}

.skeleton-button, .skeleton-textarea, .skeleton-image {
  border-radius: 8px;
}

.skeleton-avatar {
  border-radius: 50%;
}

.drag-overlay {
  pointer-events: none;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  border: 2px dashed rgba(14, 67, 51, 0.5);
}

.before-photos,
.after-photos,
.post-comment-wrapper {
  position: relative;
}

.scrollableContent{
  .vs-button-text{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    text-align: left;
  }
}

.addedProductsHeight {
  max-height: 400px;
  overflow: auto;
}

.addedProductsBorder  {
  border: 1px solid $border-color;
  box-shadow: 0px 0.94px 1.88px 0px #00000014;
  color: $font-color-primary;
  border-radius: 8px;
  &:hover {
    background-color: rgba(14, 67, 51, 0.1);
  }
}
</style>
