<template>
  <div class="mt-2 w-full" v-if="showCTA">
    <vs-button @click="popupActivo=true" color="primary" class="text-white w-full" >Send Aftercare Email</vs-button>
    <vs-popup  :active.sync="popupActivo">
      <div class="p-5">
        <div>
          <h4 class="mb-2 mt-2">Send Aftercare Email</h4>
        </div>
        <div >
          <form autocomplete="off">
            <div>
              <div>
                <vs-input
                :success="!errors.first('email')"
                :danger="errors.first('email') ? true : false"
                :danger-text="errors.first('email')"
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="Email"
                name="email"
                label="Email"
                class="w-full"
                v-model="email" />
              </div>
            </div>
          </form>
        </div>
        <div class="mt-2 flex justify-end">
          <vs-button @click="closePopup" class="p-2 mr-2" color="primary" type="border">Cancel</vs-button>
          <vs-button color="primary" type="filled" @click="sendAfterCareEmail">Send Email</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>

import { create } from 'lodash';

export default {
  props: {
    historyId: {
      type: String,
    },
    appointment: {
      type: Object,
    },
  },
  data() {
    return {
      popupActivo: false,
      email:null,
      patientDetails: {},
    };
  },
  computed: {
    showCTA() {
      return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).doctorEMRClinics.length > 0 ;
    }
  },
  methods: {
    async getPatientDetail() {
      try {
        const res = await this.$store.dispatch('patient/patientDetail2', this.$route.params.patientId);
          this.clinicId = res.data.data.clinicId;
          this.email = res.data.data.email;
      } catch (error) {
        console.error(error);
      }
    },
    closePopup() {
      this.clinicId = null;
      this.popupActivo = false;
    },
    async sendAfterCareEmail() {
      try {
        this.$vs.loading();
        const res = await this.$store.dispatch('doctor/docEmrSendAfterCareEmail', {
          appointmentId: this.$route.query.appointmentId,
          params: {
            historyId: this.historyId,
            email: this.email
          }
        });
        this.appointment.aftercareDetails = res.data.data;
        this.closePopup();
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "Aftercare email sent successfully",
          color: "success"
        });
      } catch (error) {
        console.log(error)
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Error sending aftercare email",
          color: "danger"
        });
      }
    },
  },
  created() {
    this.getPatientDetail();
  }
};
</script>
