<template>
  <div>
    <vs-button v-if="showCTA" @click="popupActivo=true" color="primary" type="border" >Generate Aftercare Document</vs-button>
    <vs-popup  :active.sync="popupActivo">
      <div class="p-5">
        <div>
          <h4 class="mb-2 mt-2">Patient Aftercare</h4>
        </div>
        <div class="p-2">
          <p>Confirm the treatment details and generate your patient aftercare.</p>
          <div class="rounded-lg shadow-md p-4 mt-2">
            <div v-for="treatment in treatmentPlans" :key="treatment._id">
              <h5 class="mb-2">{{ treatment.productName }}</h5>
              <p class="mb-4">
                <strong>Area:</strong> {{ treatment.area }}: {{ treatment.amount }} {{ treatment.unit }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-2 flex justify-end">
          <vs-button color="primary" type="filled" @click="generateAftercare">Create Aftercare</vs-button>
          <vs-button @click="closePopup" class="p-2 ml-2" color="primary" type="border">Cancel</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>

import { create } from 'lodash';

export default {
  props: {
    treatmentPlans: {
      type: Array,
    },
    aftercareDetails: {
      type: Object,
      default: () => {
        return {
          aftercareDocumentHistory: [],
          needGeneratePDF: false,
        }
      },
    },
  },
  data() {
    return {
      popupActivo: false,
      email:null,
    };
  },
  computed: {
    showCTA() {
      return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).doctorEMRClinics.length > 0 && this.aftercareDetails.needGeneratePDF;
    }
  },
  methods: {
    closePopup() {
      this.clinicId = null;
      this.popupActivo = false;
    },
    async generateAftercare() {
      try {
        this.$vs.loading();
        const aftercare = {
          ...this.aftercareDetails,
        }
        const res = await this.$store.dispatch('doctor/docEmrgenerateAfterCareEmail', this.$route.query.appointmentId);
        if(aftercare.aftercareDocumentHistory) {

          aftercare.aftercareDocumentHistory = [
            res.data.data,
            ...aftercare.aftercareDocumentHistory
          ]
        }
        else {
          aftercare.aftercareDocumentHistory = [res.data.data]
        }
        aftercare.needGeneratePDF = false
        this.$emit('updateAftercareDetails', aftercare)
        this.closePopup();
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "Aftercare document successfully generated",
          color: "success"
        });
      } catch (error) {
        console.log(error)
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Failed to generate the aftercare document. Please try again later.",
          color: "danger"
        });
      }
    },
  },
};
</script>
